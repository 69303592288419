@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800;900&display=swap');

html{
    background-color: #f5f5f5 !important;
}

body {
    font-family: 'Montserrat', sans-serif !important;
}
.content{
    // background-image: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0) 44%, rgba(255,255,255,1) 55%),url("./assets/back.png");
    background-image: url("./assets/back.png");
    background-repeat: no-repeat; 
    background-position: bottom; 
}
.header{
    padding-bottom: 50px;
    // background-image: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 100%),url("./assets/top-nuevo.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.header-timer{
    color: #FFF;
    background-color: #2F056D;
    font-weight: 900;
}

.mobil-description{
    display: none !important;
}

#root {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.content:not(:last-child){
    margin-bottom: 0 !important;
}

.barra-tabs {
    width: 100%;
    max-width: 401px;
    height: 5px;
    border-radius: 5px;
    background-color: #1f7e40;
    padding: 2px;
}

.svg-logo-white{
    width: 90% !important;
    height: auto !important;
    padding: unset !important;
    float: left;
}

.green-header-bar{
    background-color: #217F40 ;
    text-align: center;
    width: 100%;
    height: 30px;
    b{
            bottom: 13px;
            position: relative;
            color: #FFF;
            font-size: 25px;
    }
}
.green-header-bar-bottom{
    background-color: #217F40;
    width: 80%;
    margin: 0 auto;
    height: 10px;
}

.social-icon {
    svg {
        width: 30px;
        height: 30px;
        path{
            fill: #217F40;
        }
    }
}

.first-column{
    text-align: -webkit-center;
}
//MODAL SECCION
.modal-content-card{
    width: 100% !important;
}
.modal-card{
    background-image: url("./assets/back.png");
    background-size: 100%;
    padding: 2% 5% 7% 5%;
    width: 50% !important;
    .logo-left{
        padding: 2% 25% 2% 2% !important;
    }
    .cerrar-right{
        text-align: -webkit-right;
        padding-top: 4% !important;
    }
}
.cerrar-modal-card{
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    font-weight: 900;
    background-color: #257d42;
    padding: 3% 5%;
}
.ver-product-button{
    cursor: pointer;
    height: auto;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background-color: #217F40;
    border-radius: 15px;
    width: 100%;
    padding: 15px 60px;
    -webkit-box-shadow: 0px 0px 8px -4px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 8px -4px rgba(0,0,0,1);
    box-shadow: 0px 0px 8px -4px rgba(0,0,0,1);
}

.no-space-p{
   p{margin-block-end: 0 !important}
}
//MODAL SECCION END

.banner {
    vertical-align: middle;
    padding-top: 10%;
    height: 70px;
    .instagram,
    .facebook {
        width: 30px;
    }
}
.banner-shop{
    padding-top: 0.5%;
    display: grid;
}
.logo-centered {
    max-width: 300px;
    // padding-top: 27px;
    // padding-bottom: 40px;
}
.logo-shop{
    cursor: pointer;
}
.logo-container{
    display: flex !important;
    justify-content: center;
    margin-top: 25px;
}
.tabs {
    margin-bottom: 0 !important;
    ul {
        border: 0 !important;
    }
    a {
        color: #257d42 !important;
        font-weight: 900;
        font-size: larger;
    }
    a:hover {
        color: #58b678 !important;
    }
}
.barra {
    justify-content: center;
}
.home-text {
    padding: 0px 250px;
    p {
        color: #217F40 !important;
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        padding: 0rem 4rem 2rem 4rem;
        font-family: 'Montserrat';
    }
}
.divider {
    background-size: 100%;
    justify-items: center;
    vertical-align: middle;
    padding-top: 45px;
}
.left-content {
    color: white;
    padding: 50px 50px;
    text-align: center;
    .title {
        font-weight: 700 !important;
        color: white !important;
        font-size: 40px;
    }
    .text-content {
        font-size: 25px;
        padding: 50px 0;
    }
}
.ver-product-container{
    margin: 3%;
    text-align: left;
    max-width: 600px;
    a{
        display: block;
        margin-top: 6%;
    }
}
.ver-products-price{
    font-size: 20px;
    font-weight: 900;
    color: #217F40;
}
.product-text{
    max-width: 600px;
    text-align: initial;
    .product-title{
        font-size: 19px;
        font-weight: 500;
        color: #217F40;
    }
    p{
        font-size: 13px;
        font-weight: 500;
        color: #217F40;
    }
}
.slick-prev:before{
    color: #217F40;
}
.slick-next:before{
    color: #217F40;
}
.carousel-container{
    text-align: center;
    justify-content: center;
}
.carousel-text{
    background-color: #EFEFEF;
    border-radius: 15px;
    padding: 60px 0px 15px 0px;
    font-size: 13px;
    font-weight: 600;
    color: #000000;
    position: absolute;
    top: 55%;
    z-index: 0;
    min-height: 140px;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    -webkit-box-shadow: 0px 0px 8px -4px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 8px -4px rgba(0,0,0,1);
    box-shadow: 0px 0px 8px -4px rgba(0,0,0,1);
}
.carousel-price{
    font-size: 19px;
    font-weight: 700;
}
.carousel-name{
    min-height: 57px;
    padding: 0% 5%;
}
.img-carousel{
    width: unset !important;
    cursor: pointer;
    min-width: 50%;
    position: relative;
    img{
       max-width: 1200px;
       width: 100%;
       position: relative;
       z-index: 10;
    }
}
.hero-left {
    margin: -24px !important;
}
.right-content {
    img {
        width: 100%;
    }
}
.card-container{
    position: relative;
    padding: 0% 10%;
    text-align: -webkit-center;
}
.back-vermicompostera{
    width: 64%;
    height: 60%;
    background-color: #4DA94B;
    position: absolute;
    top: 0;
    left: 18%;
    z-index: -1;
    border-radius: 0px 0px 100px 100px;

}

.product-img{
    max-width: 100%;
    padding: 10% 15% 0% 15%;
}

.products-container{
    justify-items: center;
    vertical-align: middle;
    justify-content: center;
    padding: 0% 5% 3% 5%;
}

.back {
    background-color: #39a73e;
}
.back-two {
    background-color: #87c62d;
}
.btn-ver {
    background-color: #87c62d;
    text-align: center;
    padding: 20px;
    margin-top: -6px;
    a {
        font-size: 50px;
        font-weight: 900;
        color: white;
    }
    a:hover {
        color: rgba($color: #fff, $alpha: 0.5);
    }
}
.btn-ver-two {
    background-color: #39a73e;
    text-align: center;
    padding: 20px;
    margin-top: -6px;
    a {
        font-size: 50px;
        font-weight: 900;
        color: white;
    }
    a:hover {
        color: rgba($color: #fff, $alpha: 0.5);
    }
}
.feature {
    border: 2px lightgrey solid;
    //display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    line-height: 0.5em;
    padding: 50px;
    background-color: white;

    svg {
        width: 200px;
        height: 200px;
        padding-bottom: 20px;
    }
    p {
        color: #808080;
        font-weight: 600;
        font-size: 20px;
    }
}
.titulo-features {
    background-color: #257d42;
    height: 100%;
    color: white;
    font-size: 40px;
    text-align: center;
    font-weight: 900;
    justify-content: center;
    padding: 20px;
}
.contacto {
    display: flex;
    margin: 0 10px;
    // width: 60%;
    text-align: center;
    justify-content: center;
    justify-items: center;
    padding-right: 4%;
    cursor: pointer;
}
.footer-green {
    display: flex;
    background-color:#E3E3E3;
    padding-top: unset;
    justify-content: center;

    .logo-footer {
        width: 188px;
        padding-bottom: 3px;
    }
    svg {
        fill: #A9A8A8;
        width: 100%;
        // padding-left: 20px;
        path{
        fill: #A9A8A8;
        }
    }
}
.footer-green.mobil-description{
    overflow: scroll;
}
.svg-footer-social{
    margin-top: 5%;
    width: 10%;
    display: grid;
    float: left;
}

.svg-footer-container{
    align-self: center;
}

.footer-list{
    text-align: left;
    color: #A9A8A8;
    font-weight: 600;
    font-size: 13px;
    li{
        margin-bottom: 1rem;
    }
}
.footer-column-divisor{
    border-right: 2px solid;
    border-color:  #A9A8A8;
}
.form {
    background-color: #257d42;
    height: 500px;
    width: 600px;
    padding: 50px 150px;
    input {
        width: 90%;
        color: #257d42;
        height: 30px;
        margin-bottom: 7px;
        border-radius: 30px;
        padding-left: 10px;
        border: 0;
        font-size:1rem;
        padding-left: 20px;
    }
    textarea {
        width: 90%;
        color: #257d42;
        height: 80px;
        margin-top: 3px;
        margin-bottom: 3px;
        border-radius: 10px;
        padding-left: 20px;
        padding-top: 5px;
        border: 0;
        font-size:1rem;
    }
    img {
        margin-bottom: 15px;
        margin-left: 20px;
        width: 80%;
        text-align: center;
    }
    svg {
        width: 25px;
        height: 25px;
        display: block;
        margin-top: 2px;
        margin-right: 5px;
        fill: white;
    }
    input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #257d42;
        opacity: 1; /* Firefox */
        font-size: 1rem;
    }

    input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #257d42;
        font-size: 1rem;
    }

    input::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #257d42;
        font-size: 1rem;
    }
    textarea::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #257d42;
        opacity: 1; /* Firefox */
        font-size: 1rem;
    }

    textarea:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #257d42;
        font-size: 1rem;
    }

    textarea::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #257d42;
        font-size: 1rem;
    }
}
.inputContent {
    display: flex;
}
.enviar {
    background-color: #87c62d;
    color: #fff !important;
    border-color: transparent;
    width: 40% !important;
    padding: 0px 1rem;
    font-size: 1.1rem;
}

.contact-input {
    font-family: 'Montserrat';
    background-color: white;
    height: 50px;
    // border-radius: 25px;
    min-width: 500px;
    max-width: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span {
        color: #217F40;
        font-size: 1.3rem;
        margin-left: auto;
        font-weight: 600
    }
}

.contact-input-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // background: #85c816;
    height: 100%;
    width: 30%;
    // border-radius: 25px;
    svg {
        display: flex;
        fill: #217F40;
        height: 30px !important;
    }
    //margin-left: auto;
}
.modal-content{
    text-align: -webkit-center;
    .modal-text-content{
        text-align: justify;
    }
}

//PYMEDAY ONLY
footer{
    width:100%;
    background-color: #E3E3E3;
    padding-bottom: 1%;
    @media (max-width: 825px) {
        .sello-footer-pymeday-img{
            max-width: 100px;
        }
    }
}
.pymeday-logo{
    left: 0;
    top: 0;
    max-width: 35%;
    position: absolute;
}
.sello-footer-pymeday{
    position: absolute;
    display: inline-block;
    bottom: -7px;
    right: 0;
}
.sello-footer-pymeday-img{
    max-width: 200px;
    position: absolute;
    bottom: 0;
    right: 0;
}
//END PYMEDAY

// SECCION MOBIL

@media (max-width: 501px) {
    .contact-input {
        span {
            font-size: 12px !important;
        }
    }
}
@media (max-width: 321px) {
    .contact-input {
        span {
            font-size: 12px !important;
        }
    }
}
@media (max-width: 768px) {
    .header{
        // background-image: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 100%),url("./assets/top-mobil.jpg");
        // background-repeat: no-repeat;
        // background-size: cover;
    }
    footer{
        padding: 0% 0% 7%;
    }
    .divider {
        height: 170px;
    }
    .link-venta-mayor{
        padding: 1% !important;
    }
    .logo-container{
        padding: 0 5% !important;
    }
    .carousel-text{
        text-align: center;
    }
    .mobil-description{
        display: block !important;
    }
    .desktop-description{
        display: none;
    }
    .carousel-container{
        margin: unset;
        width: 100%;

    }
    .carousel-name{
        min-height: 57px;
    }
    .back-vermicompostera{
        border-radius: 60px 0px 0px 60px;
        width: 100%;
        height: 70%;
    }
    .modal-card{
        .modal-content{
            width: unset;
        }
    }
    .modal-text-content{
        overflow: hidden;
        text-align: center !important;
    }
    .social-icon svg{
        width: 25px;
        height: 25px;
    }
    .svg-logo-white{
        width: 100% !important;
    }
    .footer-column-divisor{
        border-bottom: 2px solid;
        border-right: unset;
        border-color: #A9A8A8;
    }
    .modal-card{
        background-image: unset;
        background-color: #F5F5F5;
        width: unset !important;
        margin: unset !important;
        overflow: unset !important;
        .modal-header{
            display: flex;
        }
        .logo-left{
            padding: 8% 0% 2% 2% !important;
        }
        .cerrar-right{
            padding-top: 9% !important;
        }
        .column{
            padding: 1.05rem !important;
        }
    }
    .card-container{
        padding: 3% 0%;
        margin: 4% 3%;
    }
    .contact-input {
        min-width: 155px;
        height: 35px;
        span {
            font-size: 12px;
        }
    }
    .contact-input-icon{
        width: 20%;
        svg{
            width: 20px !important;
        }
    }
    .home-text {
        padding: 0px 20px;
        p{
            font-size: 14px;
            padding: unset;
        }
    }
    .btn-ver {
        background-color: #39a73e;
    }
    .contacto {
        display: flex;
        margin: unset;
        padding-right: 2%;
    }
    .footer-green {
        padding-left: 15px;
        padding-right: 15px;
        .svg-footer-container{
            display: flex;
            vertical-align: middle;
            justify-content: center;
        }
        .svg-footer-social {
            margin-top: unset;
        }
        .logo-footer{
            width: 90px;
        }
        a{
            align-self: center;
        }
        svg{
            vertical-align: middle;
        }
    }
    .form {
        background-color: #257d42;
        height: 380px;
        width: 100%;
        padding: 10px 10px;
    }
    .logo-centered {
        width: 100%;
        max-width: unset !important;
    }
    body{
        width: 100%;
        overflow: hidden;
    }
    .is-pulled-right{
        float: unset;
    }
    .banner{
        padding-top: 7%;
        width: min-content;
        .instagram{
            padding: 0%;
        }
        .facebook{
            padding: 0%;
        }
    }
    html {
        // background-image: url("./assets/back.png");
        // background-repeat: no-repeat;
        // background-size: cover;
        background-color: #f5f5f5;
    }
    .product-card-text{
        font-size: 10px;
        padding: 9px;
        .product-text{
            font-size: 10px;
            margin-bottom: 3px !important;
        }
        .card-price{
            font-size: 20px;
            float: unset;
        }
        div{
            float: unset;
            display: grid;
            .ver-product-button{
                padding:5%;
                font-size:13px;
            }
        }
    }
    .img-carousel{
        text-align: -webkit-center;
    }
}

@media (max-width: 1050px) {
    .link-venta-mayor {
        margin-bottom: 5% !important;
    }
}

@media (min-width: 768px) {
    html {
        // background-image: url("./assets/back.png");
        // background-repeat: no-repeat;
        // background-size: cover;
        background-color: #f5f5f5;
    }
}

@media (min-width: 1921px) {
    footer{
        padding: unset;
    }
}

@media (max-width: 1279px) {
    .footer-green{
        padding: unset;
    }
}

@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}


//PAGES DESCRIPTION

.text-page-content{
    padding: 3% 10% 10%;
}

.text-page-title{
    text-align: center;
    font-size: 35px;
}

.link-venta-mayor{
    width: 100%;
    background-color: #217F40;
    text-align: center;
    margin-bottom: 2%;
    color: #217F40;
    font-weight: 700;
    padding: 0.7% 30%;
    max-height: 60px;
    div{
        background-color: white;
        cursor: pointer;
        span{
            vertical-align: super;
        }
    }
    svg{
        max-width: 30px;
        margin-left: 10px;
        vertical-align: text-bottom;
        path{
            fill: #217F40;
        }
    }
}
